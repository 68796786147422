import React from 'react';
import dynamic from 'adapter/next/dynamic';
import { Box } from '@superside/ui';
import { ctaVariantOptions } from '@superside/sanity-studio/schema/blocks/SuperHero/types';
import { type SuperHeroCtaVariantProps } from '../types';
import { HeroCtaGroup } from '../../../components';

const EmailCapturingForm = dynamic(
  () => import(/* webpackChunkName: "tier-0" */ '../../../components/EmailCapturingForm')
);

const SuperHeroCtaVariant: React.FC<SuperHeroCtaVariantProps> = ({
  ctaVariant,
  primaryCta,
  secondaryCta,
  capturingForm,
  disableSecondaryCtaMobile
}) => {
  if (ctaVariant === ctaVariantOptions.NO_CTA) {
    return null;
  }

  if (ctaVariant === ctaVariantOptions.FIELD_CTA) {
    return (
      <Box className='480:w-[423px] 480:max-w-full <768:mx-auto z-[1] mt-10'>
        <EmailCapturingForm
          redirectLink={capturingForm.redirectLink || '/get-started'}
          buttonLabel={capturingForm.submitButtonText}
          placeholder={capturingForm.placeholderText}
          formId={capturingForm.hubspotFormId}
          trackingEvents={capturingForm.trackingEvents}
        />
      </Box>
    );
  }

  return (
    <HeroCtaGroup
      primaryCta={primaryCta}
      secondaryCta={ctaVariant === ctaVariantOptions.DOUBLE_CTA ? secondaryCta : undefined}
      disableSecondaryCtaMobile={
        ctaVariant === ctaVariantOptions.DOUBLE_CTA ? disableSecondaryCtaMobile : undefined
      }
    />
  );
};

export default SuperHeroCtaVariant;
