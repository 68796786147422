/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { get as getCookie } from 'js-cookie';
import { nanoid } from 'nanoid';
import { useRouter } from 'adapter/next/router';
import {
  FORM_TRACKING_PROVIDERS,
  TRACKING_EVENT_NAMES,
  baseTrackingFieldsGenerator,
  useAnalytics
} from '@konsus/analytics';
import { Box } from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { useAtomMutator } from '@konsus/atoms';
import { SCHEDULING_PROVIDER } from '../../types/form';
import { useEventId } from '../../utils/form';
import { chilipiperLoaderAtom } from '../../components/ChilipiperLoader/chilipiperLoaderAtom';
import { hubspotEmbedFormStyle } from './utils/styles';
import { CHILI_PIPER_CONFIG, isDev, minimalCostNoteStyle } from './utils';
import { useChiliPiper } from './useChiliPiper';
import { submitChiliPiperFormAndScheduleCall, submitChiliPiperForm } from './bookCallUtils';
import { useRedirect } from './useRedirect';
import { type CommonFormVariants } from './types';

const reCaptchaWidgetId = process.env.RECAPTCHA_V3_WIDGET_ID;

export type LeadDataType = {
  ticket?: string;
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  company_size: string;
  Chili_Piper_latest_form__c?: string;
  subscription_checkbox_to_blog?: string;
  phone: string;
};

export const BookCallEmbedForm: React.FC<CommonFormVariants> = ({
  openScheduler = true,
  formName,
  routerName,
  formId,
  redirectLink = '/call-confirmed',
  newWindow = false,
  hideMinimalCostNote = false,
  bookCallWarning,
  trackingEvents,
  useFormPlaceholder = false,
  formPlaceholderHeight,
  formOutput,
  // TODO remove after formOutput complete release
  isTicket: deprecatedIsTicket
}) => {
  const isTicket = formOutput ? formOutput === 'ticket' : deprecatedIsTicket;
  const isRedirect = formOutput ? formOutput === 'redirect' : !deprecatedIsTicket;

  const [minimalCompanySizeSelected, setMinimalCompanySizeSelected] = useState(false);
  const [scriptIsReady, setScriptIsReady] = useState(false);
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const [targetFormId, setTargetFormId] = useState<string | null>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const ticketId = nanoid(10);

  const eventId = useEventId();
  const { track } = useAnalytics();
  const redirect = useRedirect();
  const { asPath } = useRouter();

  useEffect(() => {
    setTargetFormId(
      'embed-form-target-' + Math.floor((1 + Math.random()) * 0x10000000000).toString(16)
    );
  }, []);

  useChiliPiper(SCHEDULING_PROVIDER.CHILIPIPER);

  const baseTrackingFields = useMemo(() => {
    return baseTrackingFieldsGenerator(eventId, formId, trackingEvents, asPath);
  }, [eventId, formId, trackingEvents, asPath]);

  const setChiliPiperLoader = useAtomMutator(chilipiperLoaderAtom);

  const formSubmitHandler = useCallback(
    async (evt: {
      data: {
        type?: string;
        eventName?: string;
        data?: any;
      };
    }) => {
      if (evt.data.type !== 'hsFormCallback') {
        return false;
      }

      const { eventName, data: form } = evt.data;

      if (eventName === 'onFormReady' && targetFormId) {
        const formElement = document.getElementById(targetFormId);
        const selectCompanySize = formElement?.querySelector(
          '[name="company_size"]'
        ) as HTMLSelectElement | null;

        selectCompanySize?.addEventListener('change', () => {
          setMinimalCompanySizeSelected(selectCompanySize.value === '1-10');
        });
      }

      if (eventName === 'onFormSubmit') {
        setMinimalCompanySizeSelected(false);

        let lead = {} as LeadDataType;

        for (const key in form) {
          // @ts-ignore
          lead[form[key].name] = form[key].value;
        }
        if (Object.keys(lead).length <= 1) {
          lead = form;
        }

        lead.Chili_Piper_latest_form__c = formName;

        lead.subscription_checkbox_to_blog = lead.subscription_checkbox_to_blog ? 'true' : 'false';

        const chiliPiperRouter =
          isDev || lead.email.includes('@superside.com')
            ? 'test_router'
            : routerName || CHILI_PIPER_CONFIG.router;

        if (isTicket) {
          localStorage.setItem(ticketId, `${lead.firstname} ${lead.lastname}`);
        }

        if (openScheduler) {
          await submitChiliPiperFormAndScheduleCall({
            lead,
            routerName: chiliPiperRouter,
            track,
            eventId,
            setChiliPiperLoader
          });
        } else {
          await submitChiliPiperForm({ lead, routerName: chiliPiperRouter, eventId });
        }

        if (redirectLink && (isTicket || isRedirect)) {
          redirect(
            isTicket
              ? `${redirectLink}${redirectLink.includes('?') ? '&' : '?'}ticket=${ticketId}`
              : redirectLink,
            newWindow
          );
        }
      }
    },
    [
      targetFormId,
      formName,
      routerName,
      openScheduler,
      redirectLink,
      eventId,
      redirect,
      newWindow,
      ticketId,
      isRedirect,
      isTicket,
      setChiliPiperLoader,
      track
    ]
  );

  useEffect(() => {
    // @ts-ignore
    if (!window.hbspt) {
      const script = document.createElement('script');

      script.onload = () => setScriptIsReady(true);
      script.src = '//js.hsforms.net/forms/v2.js?pre=1';
      script.async = true;

      document.body.appendChild(script);
    }

    window.addEventListener('message', formSubmitHandler);

    return () => {
      window.removeEventListener('message', formSubmitHandler);
    };
  }, [formSubmitHandler]);

  const setRecaptchaScore = useCallback(() => {
    if (formRef.current) {
      const recaptchaField = formRef.current.querySelector(
        'input[name="recaptcha_score"]'
      ) as HTMLInputElement;

      if (recaptchaField) {
        const script = document.createElement('script');

        script.onload = () => setRecaptchaReady(true);
        script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaWidgetId}`;
        script.async = true;

        document.body.appendChild(script);
      }
    }
  }, []);

  const setTicketRefField = useCallback(() => {
    if (formRef.current) {
      const ticketRefField = formRef.current.querySelector(
        'input[name="ticket_referral"]'
      ) as HTMLInputElement;

      if (ticketRefField) {
        ticketRefField.value = ticketId;
      }
    }
  }, [ticketId]);

  useEffect(() => {
    if (recaptchaReady && formRef.current) {
      const recaptchaField = formRef.current.querySelector(
        'input[name="recaptcha_score"]'
      ) as HTMLInputElement;

      (window as any)?.grecaptcha.ready(() => {
        (window as any)?.grecaptcha
          ?.execute(reCaptchaWidgetId, { action: 'submit' })
          .then(async (token: any) => {
            const body = {
              recaptchaResponse: token
            };

            try {
              const response = await fetch('/api/recaptcha-verify', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(body)
              });

              if (response.ok) {
                const json = await response.json();

                if (json.success && json.score) {
                  recaptchaField.value = json.score;
                }
              } else {
                throw new Error(response.statusText);
              }
            } catch (error: any) {
              throw new Error(error.message);
            }
          })
          .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      });
    }
  }, [recaptchaReady]);

  useEffect(() => {
    // @ts-ignore
    if (window.hbspt && targetFormId) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '6380455',
        formId: formId || '122986f2-8cb6-45e3-8a9c-f4ce5eec19c5',
        target: `#${targetFormId}`,
        cssClass: 'hubspotEmbedForm',
        onFormReady: () => {
          setRecaptchaScore();
          setTicketRefField();
        },
        onFormError: (e: string) => {
          // eslint-disable-next-line no-console
          console.error('Embed form failed', e);

          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION_FAILURE, {
            ...baseTrackingFields,
            error: e.toLowerCase(),
            provider: FORM_TRACKING_PROVIDERS.HUBSPOT_EMBED
          });
        },
        onFormSubmitted: (_: any, data: { submissionValues: any }) => {
          const hasPrefilledForm = !!getCookie('hubspotutk');
          const lead = data.submissionValues;

          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION, {
            ...baseTrackingFields,
            first_name: lead.firstname,
            last_name: lead.lastname,
            email: lead.email,
            company_name: lead.company,
            company_size: lead.company_size,
            phone: lead.phone,
            ...(lead?.subscription_checkbox_to_blog === 'true' ? { blog_subscription: true } : {}),
            provider: FORM_TRACKING_PROVIDERS.HUBSPOT_EMBED,
            prefilled_form: hasPrefilledForm
          });
        }
      });
    }
  }, [formId, targetFormId, scriptIsReady, setRecaptchaScore, track, baseTrackingFields]);

  return targetFormId ? (
    <Box direction='column' width='384px'>
      <div ref={formRef} className={hubspotEmbedFormStyle} id={targetFormId}>
        {useFormPlaceholder ? <FormPlaceholder height={formPlaceholderHeight} /> : null}
      </div>
      {minimalCompanySizeSelected && !hideMinimalCostNote && bookCallWarning ? (
        <Box
          border={{ color: 'callout-dark' }}
          margin={{ bottom: 'small', top: 'medium' }}
          pad={{ horizontal: 'medium' }}
          background={{ color: '#FF635F', dark: true }}
          className={minimalCostNoteStyle}
        >
          <SanityBlockComponent blocks={bookCallWarning}></SanityBlockComponent>
        </Box>
      ) : null}
    </Box>
  ) : null;
};

export default BookCallEmbedForm;

const FormPlaceholder: React.FC<{ height?: string }> = ({ height = '707.5' }) => {
  return (
    <div
      style={{ '--fp-height': `${height}px` } as React.CSSProperties}
      className='h-[var(--fp-height)] overflow-hidden pt-[6px]'
    >
      <div className='mb-[25px]'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[130px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>

      <div className='mb-[25px]'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[130px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>

      <div className='mb-[25px]'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[150px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>

      <div className='mb-[25px]'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[140px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>

      <div className='mb-[25px]'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[140px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>

      <div className='mb-[25px]'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[160px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>

      <div className='mb-5'>
        <div className='bg-grey-500 mb-2 h-[15.5px] w-[160px] animate-pulse' />
        <div className='bg-grey-400 h-[50px] animate-pulse' />
      </div>
    </div>
  );
};
