import React from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';
import { Box, useScreenSize } from '@konsus/superside-kit';
import { tryToExtractImageDetails, NextFutureImage } from '@konsus/image';
import styles from './LogoSaladItem.module.css';
import { type LogoProps } from './types';

export const LogoSaladItem: React.FC<
  LogoProps & {
    flexWidth?: string;
    animated?: boolean;
  }
> = (props) => {
  const {
    image,
    height,
    title,
    link,
    isNewWindow = false,
    isNofollow,
    flexWidth,
    animated = false
  } = props;
  const isHashLink = link?.startsWith('#');
  const imageDetails = tryToExtractImageDetails(image);
  const finalHeight = height ? parseInt(height) : 35;
  const finalWidth = finalHeight * imageDetails.aspectRatio;
  const { breakpoints } = useScreenSize();

  const img = (
    <NextFutureImage
      image={image}
      width={finalWidth}
      height={finalHeight}
      alt={title}
      loading='eager'
      placeholder='empty'
    />
  );

  const contents = link ? (
    <Link
      href={link}
      target={isNewWindow && !isHashLink ? '_blank' : '_self'}
      rel={isNofollow && !isHashLink ? 'nofollow noreferrer' : undefined}
    >
      {img}
    </Link>
  ) : (
    img
  );
  const animatedHeight = !breakpoints?.medium ? `${0.75 * finalHeight}px` : `${finalHeight}px`;
  const elementHeight = animated ? animatedHeight : `78px`;
  const elementWidth = !breakpoints?.medium ? `${0.75 * finalWidth}px` : `${finalWidth}px`;

  return (
    <Box
      align='center'
      justify='center'
      className={cn(
        `${styles.itemStyle} h-[var(--logo-height-3917)] w-[var(--logo-width-22d3)] select-none [&_img]:select-none [&_a]:self-center`,
        !animated &&
          `${styles.staticStyle} 1024:flex-[var(--flex-95c4)] 360:flex-[0_0_calc(33.3333%_-_16px)] 768:flex-[0_0_calc(25%_-_16px)] flex-[0_0_calc(50%_-_16px)] [&_img]:select-none [&_a]:self-center`,
        animated && '1024:mr-24 768:mr-[72px] mr-10 h-[78px]',
        'brightness-0 contrast-[91%] hue-rotate-[185deg] invert-[7%] saturate-100 sepia-[15%] filter dark:filter-none'
      )}
      style={
        {
          '--flex-95c4': animated ? ` 1 0 100%` : flexWidth,
          '--logo-height-3917': elementHeight,
          '--logo-width-22d3': elementWidth
        } as React.CSSProperties
      }
    >
      {contents}
    </Box>
  );
};
