import React from 'react';

export const trustpilotSvg = (
  <svg
    width='30'
    height='29'
    viewBox='0 0 30 29'
    className='1280:w-[30px] 1280:h-[29px] h-6 w-[25px]'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M29.6964 11.2702H18.6428L15.2286 0.75L11.8036 11.2702L0.75 11.2595L9.70174 17.7679L6.27682 28.2774L15.2286 21.7797L24.1696 28.2774L20.7554 17.7679L29.6964 11.2702Z'
      fill='#00B67A'
    />
  </svg>
);

export const g2Svg = (
  <svg
    className='1280:w-[25px] 1280:h-[26px] h-[22px] w-[21px]'
    viewBox='0 0 25 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_8781_10232)'>
      <path
        d='M17.394 18.3304C18.3275 19.9504 19.2506 21.5519 20.1731 23.1517C16.0885 26.2789 9.73362 26.6568 5.02925 23.0556C-0.38446 18.9082 -1.27425 11.8315 1.62416 6.73745C4.95776 0.878155 11.196 -0.417665 15.2126 0.53251C15.104 0.768493 12.6983 5.75917 12.6983 5.75917C12.6983 5.75917 12.5081 5.77166 12.4006 5.77374C11.2134 5.82406 10.3291 6.1003 9.38138 6.59031C8.34157 7.13289 7.45049 7.9219 6.78601 8.88837C6.12153 9.85484 5.70388 10.9694 5.56958 12.1345C5.42944 13.3161 5.59283 14.514 6.04432 15.6149C6.42605 16.5456 6.96604 17.3723 7.68995 18.0701C8.80045 19.1418 10.122 19.8053 11.6538 20.025C13.1043 20.2332 14.4994 20.0271 15.8067 19.3701C16.297 19.1241 16.7142 18.8524 17.2018 18.4796C17.2639 18.4394 17.3191 18.3884 17.394 18.3304Z'
        fill='#FF492C'
      />
      <path
        d='M17.4035 4.12232C17.1665 3.88911 16.9468 3.67395 16.7282 3.4574C16.5977 3.32831 16.4721 3.19401 16.3385 3.06803C16.2906 3.02257 16.2344 2.96045 16.2344 2.96045C16.2344 2.96045 16.2798 2.86398 16.2993 2.82442C16.555 2.31115 16.9559 1.93601 17.4313 1.63756C17.957 1.30503 18.5696 1.13584 19.1914 1.15137C19.9872 1.16699 20.7271 1.36514 21.3514 1.89888C21.8122 2.29276 22.0486 2.79249 22.0902 3.38904C22.1596 4.39543 21.7432 5.16619 20.9162 5.70409C20.4303 6.02059 19.9063 6.26524 19.3809 6.55502C19.0911 6.715 18.8434 6.85555 18.5602 7.14497C18.311 7.43544 18.2989 7.70925 18.2989 7.70925L22.0631 7.70439V9.3809H16.2528C16.2528 9.3809 16.2528 9.26638 16.2528 9.21884C16.2306 8.39498 16.3267 7.61971 16.7039 6.87151C17.0509 6.18508 17.5902 5.68258 18.2381 5.29563C18.7372 4.99753 19.2626 4.74385 19.7627 4.44714C20.0712 4.26425 20.2891 3.996 20.2874 3.60697C20.2874 3.27313 20.0444 2.97642 19.6974 2.88376C18.8791 2.66305 18.0462 3.01528 17.6131 3.76418C17.55 3.8735 17.4854 3.98212 17.4035 4.12232Z'
        fill='#FF492C'
      />
      <path
        d='M24.6815 16.5466L21.5092 11.0684H15.2318L12.0391 16.6032H18.3627L21.4832 22.0554L24.6815 16.5466Z'
        fill='#FF492C'
      />
    </g>
    <defs>
      <clipPath id='clip0_8781_10232'>
        <rect width='24.6841' height='25.3771' fill='white' transform='translate(0 0.25)' />
      </clipPath>
    </defs>
  </svg>
);

export const starSvg = (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='22' viewBox='0 0 24 22' fill='none'>
    <path
      d='M11.8217 0.548822C11.8778 0.376076 12.1222 0.376076 12.1783 0.548822L14.6521 8.16224C14.6772 8.23949 14.7492 8.2918 14.8304 8.2918H22.8356C23.0172 8.2918 23.0928 8.52422 22.9458 8.63099L16.4695 13.3363C16.4037 13.3841 16.3762 13.4687 16.4014 13.546L18.8751 21.1594C18.9312 21.3321 18.7335 21.4758 18.5866 21.369L12.1102 16.6637C12.0445 16.6159 11.9555 16.6159 11.8898 16.6637L5.41343 21.369C5.26649 21.4758 5.06877 21.3321 5.1249 21.1594L7.59865 13.546C7.62375 13.4687 7.59625 13.3841 7.53053 13.3363L1.05418 8.63099C0.907232 8.52422 0.982752 8.2918 1.16439 8.2918H9.16961C9.25084 8.2918 9.32283 8.23949 9.34793 8.16224L11.8217 0.548822Z'
      fill='#7E98FD'
    />
  </svg>
);
