import React from 'react';
import { Box, Heading, cn } from '@superside/ui';
import { RichTextFomo } from '@konsus/sanity-components/src/components/RichTextFomo';
import {
  type HeroLeftComponentTypes,
  contentComponentOptions,
  heroLeftComponentOptions,
  type ContentComponentTypes
} from '@superside/sanity-studio/schema/blocks/SuperHero/types';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import {
  Superscript,
  StarRating,
  AnimatedAsset,
  VisualAsset,
  TrustpilotReview
} from '../../../components';
import {
  contentComponentVariants,
  heroLeftExtraComponentVariants,
  superHeroContainerVariants,
  superHeroContentVariants,
  superHeroContentWrapperVariants
} from '../variants';
import { type SuperHeroContainerProps } from '../types';
import AnimatedLogoSalad from '../../LogoSalad/AnimatedLogoSalad';
import BookCallEmbedForm from '../../BookCall/BookCallEmbedForm';
import { type CommonFormVariants } from '../../BookCall/types';
import SuperHeroCtaVariant from './SuperHeroCtaVariant';

const CONTENT_COMPONENT_MAP: Record<ContentComponentTypes, any> = {
  [contentComponentOptions.FOMO_COUNTDOWN]: ({
    fomoCountdownContent
  }: {
    fomoCountdownContent: SuperHeroContainerProps['fomoCountdownContent'];
  }) => <RichTextFomo node={{ ...fomoCountdownContent }} className='mt-0' />,
  [contentComponentOptions.TRUSTPILOT_REVIEW]: ({
    trustpilotReviewContent
  }: {
    trustpilotReviewContent: SuperHeroContainerProps['trustpilotReviewContent'];
  }) => <TrustpilotReview {...trustpilotReviewContent} />,
  [contentComponentOptions.LOGO_SALAD]: ({
    miniLogoSaladContent
  }: {
    miniLogoSaladContent: SuperHeroContainerProps['miniLogoSaladContent'];
  }) => (
    <div className='w-full overflow-hidden'>
      <AnimatedLogoSalad
        logos={miniLogoSaladContent?.items}
        rows={1}
        speed={miniLogoSaladContent.speed}
      />
    </div>
  ),
  [contentComponentOptions.STAR_RATING]: ({
    starRatingContent
  }: {
    heroVariant: SuperHeroContainerProps['heroVariant'];
    starRatingContent: SuperHeroContainerProps['starRatingContent'];
  }) => <StarRating {...starRatingContent} />
};

const HERO_LEFT_COMPONENT_MAP: Record<HeroLeftComponentTypes, any> = {
  [heroLeftComponentOptions.FOMO_COUNTDOWN]: ({
    fomoCountdownHeroLeft
  }: {
    fomoCountdownHeroLeft: SuperHeroContainerProps['fomoCountdownHeroLeft'];
  }) => <RichTextFomo node={{ ...fomoCountdownHeroLeft }} />,
  [heroLeftComponentOptions.TRUSTPILOT_REVIEW]: null,
  [heroLeftComponentOptions.STAR_RATING]: ({
    starRatingHeroLeft
  }: {
    starRatingHeroLeft: SuperHeroContainerProps['starRatingHeroLeft'];
  }) => <StarRating {...starRatingHeroLeft} />,
  [heroLeftComponentOptions.VISUAL_ASSET]: ({
    visualAssetHeroLeft
  }: {
    visualAssetHeroLeft: SuperHeroContainerProps['visualAssetHeroLeft'];
  }) => <VisualAsset loading='eager' {...visualAssetHeroLeft} />,
  [heroLeftComponentOptions.FORM]: ({
    embedFormOptionsHeroLeft
  }: {
    embedFormOptionsHeroLeft: SuperHeroContainerProps['embedFormOptionsHeroLeft'];
  }) => (
    <Box className='768:w-[304px] 1024:w-96 w-auto px-4'>
      <BookCallEmbedForm {...(embedFormOptionsHeroLeft as CommonFormVariants)} />
    </Box>
  ),
  [heroLeftComponentOptions.ANIMATED_ASSET]: ({
    animatedAssetHeroLeft
  }: {
    animatedAssetHeroLeft: SuperHeroContainerProps['animatedAssetHeroLeft'];
  }) => <AnimatedAsset {...animatedAssetHeroLeft} />
};

const SuperHeroContainer: React.FC<SuperHeroContainerProps> = ({
  contentWidth,
  customContentWidth,
  heroVariant,
  heroContainerDesktop,
  heroAlignmentDesktop,
  superscript,
  title,
  headerRichText,
  description,
  ctaVariant,
  primaryCta,
  secondaryCta,
  disableSecondaryCtaMobile,
  capturingForm,
  heroContainerMobile,
  heroAlignmentMobile,
  imageSizeMobile,
  paddingMobile,
  heroLeftComponent,
  contentComponent,
  fomoCountdownContent,
  starRatingContent,
  miniLogoSaladContent,
  trustpilotReviewContent,
  fomoCountdownHeroLeft,
  starRatingHeroLeft,
  visualAssetHeroLeft,
  embedFormOptionsHeroLeft,
  extraComponentAlignment,
  animatedAssetHeroLeft
}) => {
  const renderContentComponent = CONTENT_COMPONENT_MAP[contentComponent];
  const renderHeroLeftComponent = HERO_LEFT_COMPONENT_MAP[heroLeftComponent];

  return (
    <div className={superHeroContainerVariants({ heroContainerDesktop })}>
      <div
        className={superHeroContentWrapperVariants({
          heroVariant,
          extraComponentAlignment,
          heroContainerDesktop,
          heroContainerMobile,
          heroAlignmentDesktop,
          heroAlignmentMobile,
          imageSizeMobile,
          paddingMobile
        })}
      >
        <div
          className={superHeroContentVariants({ heroVariant, contentWidth })}
          style={
            {
              '--custom-width-xl': customContentWidth?.desktopXL,
              '--custom-width-l': customContentWidth?.desktopL,
              '--custom-width-tablet': customContentWidth?.tablet
            } as React.CSSProperties
          }
        >
          {superscript ? <Superscript content={superscript} /> : null}
          {headerRichText ? (
            <SanityBlockComponent
              blocks={headerRichText}
              renderNormalAsHeading={{ level: 'h2', as: 'h1' }}
            />
          ) : title ? (
            <Heading type='h1'>{title}</Heading>
          ) : null}
          {description ? (
            <div className='text-wrap 768:text-balance 1280:text-wrap mt-4 max-w-[600px] '>
              <SanityBlockComponent blocks={description} />
            </div>
          ) : null}
          <div className='<768:self-stretch'>
            <SuperHeroCtaVariant
              ctaVariant={ctaVariant}
              primaryCta={primaryCta}
              secondaryCta={secondaryCta}
              capturingForm={capturingForm}
              disableSecondaryCtaMobile={disableSecondaryCtaMobile}
            />
          </div>

          <div
            className={contentComponentVariants({ heroVariant, contentComponent, contentWidth })}
          >
            {renderContentComponent?.({
              heroVariant,
              fomoCountdownContent,
              starRatingContent,
              miniLogoSaladContent,
              trustpilotReviewContent
            })}
          </div>
        </div>

        {heroVariant === 'left' && (
          <div
            className={cn(
              heroLeftExtraComponentVariants({ extraComponentAlignment, heroLeftComponent })
            )}
          >
            {renderHeroLeftComponent?.({
              fomoCountdownHeroLeft,
              starRatingHeroLeft,
              animatedAssetHeroLeft,
              visualAssetHeroLeft,
              embedFormOptionsHeroLeft
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperHeroContainer;
