import React, { type CSSProperties } from 'react';
import { Play } from 'phosphor-react';
import { GlassButton } from '@superside/ui';
import { useAutoPopupVideo, useVideoHandler } from '@konsus/hooks';
import useScrollRotate from './useScrollRotate';
import { type AnimatedAssetProps } from './types';
import ImageAsset from './ImageAsset';
import styles from './AnimatedAsset.module.css';

const MAXIMUM_ROTATE = 8;
const MAXIMUM_TOP = 40;
const MAXIMUM_LEFT = 32;

export const AnimatedAsset: React.FC<AnimatedAssetProps> = ({
  backAsset,
  middleAsset,
  frontAsset,
  topBackgroundAsset,
  bottomBackgroundAsset,
  videoPopup,
  ctaText = 'Play video'
}) => {
  const progress = useScrollRotate();
  const rotation = Math.min(progress * MAXIMUM_ROTATE, MAXIMUM_ROTATE);
  const maxTop = Math.min(progress * MAXIMUM_TOP, MAXIMUM_TOP);
  const maxLeft = Math.min(progress * MAXIMUM_LEFT, MAXIMUM_LEFT);

  const onCardClickHandler = useVideoHandler({
    ...videoPopup
  });

  useAutoPopupVideo({
    ...videoPopup,
    onCardClickHandler
  });

  return (
    <div
      style={
        {
          '--max-left': `${maxLeft}px`,
          '--max-left-negative': `-${maxLeft}px`,
          '--rotation': `${rotation}deg`,
          '--rotation-negative': `-${rotation}deg`,
          '--max-top': `-${maxTop}px`
        } as CSSProperties
      }
      className='768:w-[328px] 1280:w-[600px] group relative aspect-square h-full w-screen'
    >
      {videoPopup?.mediaType ? (
        <div className='absolute inset-0 z-10 m-auto h-fit w-fit'>
          <GlassButton
            label={ctaText}
            size='lg'
            icon={<Play size={16} />}
            onClick={onCardClickHandler}
          />
        </div>
      ) : null}

      <ImageAsset
        asset={frontAsset}
        className={`${styles.frontAsset} group-hover:hover-hover:!-translate-y-8  group-hover:hover-hover:!translate-x-8  group-hover:hover-hover:!rotate-[8deg] z-5`}
      />
      <ImageAsset
        asset={middleAsset}
        className={`${styles.middleAsset} group-hover:hover-hover:!-translate-y-10 z-3'`}
      />
      <ImageAsset
        asset={backAsset}
        className={`${styles.backAsset} group-hover:hover-hover:!-translate-x-8  group-hover:hover-hover:!-translate-y-8  group-hover:hover-hover:!rotate-[-8deg] z-0`}
      />
      <ImageAsset
        asset={topBackgroundAsset}
        className='-right-[24%] -top-[9.1%] z-0 h-[59%] w-[54.8%]'
      />
      <ImageAsset
        asset={bottomBackgroundAsset}
        className='-bottom-[46%] -right-[3%] z-0 h-[70.1%] w-[96%]'
      />
    </div>
  );
};

export default AnimatedAsset;
