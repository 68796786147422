import React from 'react';
import { cn } from '@superside/ui';
import { PageSection } from '../../components';
import SuperHeroBackground from './components/SuperHeroBackground';
import SuperHeroContainer from './components/SuperHeroContainer';
import { type SuperHeroProps } from './types';

const SuperHero: React.FC<SuperHeroProps> = ({
  backgroundSettings,
  heroContainerDesktop,
  hasCustomizedMobile,
  heroContainerMobile = heroContainerDesktop,
  heroAlignmentMobile,
  paddingMobile,
  hashId,
  ...rest
}) => {
  const mobileBackgroundOptions = hasCustomizedMobile
    ? { heroContainerMobile, heroAlignmentMobile, paddingMobile }
    : {};

  return (
    <div className='relative w-full'>
      <SuperHeroBackground
        backgroundSettings={backgroundSettings}
        heroContainerDesktop={heroContainerDesktop}
        heroContainerMobile={heroContainerMobile}
      />

      <PageSection
        paddingBottom='none'
        paddingTop='none'
        className={cn(
          heroContainerDesktop === 'fixed'
            ? '768:h-[calc(100vh-theme(spacing.header))] 768:max-h-[calc(656px-theme(spacing.header))] 1367:max-h-[calc(696px-theme(spacing.header))] 1537:max-h-[calc(965px-theme(spacing.header))] [&>div]:h-full'
            : '',
          '<768:px-0'
        )}
        hashId={hashId}
      >
        <SuperHeroContainer
          {...rest}
          {...mobileBackgroundOptions}
          heroContainerDesktop={heroContainerDesktop}
          imageSizeMobile={backgroundSettings?.imageSizeMobile}
        />
      </PageSection>
    </div>
  );
};

export default SuperHero;
