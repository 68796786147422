import React from 'react';
import { starSvg } from './icons';

export interface RatingProps {
  icon: JSX.Element;
  rating: string;
  platform: string;
  url: string;
}

export const Rating: React.FC<RatingProps> = ({ icon, rating, platform, url }) => {
  if (!rating) {
    return null;
  }

  return (
    <div className='1280:gap-4 flex items-center gap-3'>
      <div className='bg-white-100 rounded-full p-2'>{icon}</div>
      <div className='text-dark dark:text-light flex flex-col'>
        <div className='flex items-start gap-1'>
          <span className='text-2xl font-bold leading-[100%]'>{rating}</span>
          {starSvg}
        </div>
        {url ? (
          <a
            href={url}
            target='_blank'
            rel='nofollow noreferrer'
            className='text-start transition-all duration-200 hover:underline hover:transition-all hover:duration-200 hover:[text-shadow:0_0_0.01px_black;] dark:hover:[text-shadow:0_0_0.01px_white;]'
          >
            on {platform}
          </a>
        ) : (
          <span className='text-start'>on {platform}</span>
        )}
      </div>
    </div>
  );
};
