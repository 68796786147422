import { useState, useEffect } from 'react';

const MAXIMUM_AVAILABLE_AMOUNT_OF_ROWS = 3;
const AVAILABLE_ROWS = Array.from(
  { length: MAXIMUM_AVAILABLE_AMOUNT_OF_ROWS },
  (_, index) => index + 1
);

export const MINIMUM_LOGOS_IN_ONE_LINE = 6;

export const useRowsLogosCount = (logosCount: number, rows: number) => {
  const [logosInLineCount, setLogosInLineCount] = useState(
    logosCount >= MINIMUM_LOGOS_IN_ONE_LINE ? logosCount : MINIMUM_LOGOS_IN_ONE_LINE
  );
  const [sliders, setSliders] = useState<null[]>([]);

  useEffect(() => {
    if (logosCount >= MINIMUM_LOGOS_IN_ONE_LINE && AVAILABLE_ROWS.includes(rows)) {
      const setRowsAndLogos = (n: number) => {
        if (!AVAILABLE_ROWS.includes(n)) {
          return false;
        }

        if (logosCount >= MINIMUM_LOGOS_IN_ONE_LINE * n) {
          setSliders(Array.apply(null, Array(n)));
          setLogosInLineCount(
            Math.floor(logosCount / n) >= MINIMUM_LOGOS_IN_ONE_LINE
              ? Math.floor(logosCount / n)
              : MINIMUM_LOGOS_IN_ONE_LINE
          );
        } else {
          setRowsAndLogos(n - 1);
        }
      };

      setRowsAndLogos(rows);
    }
  }, [logosCount, rows]);

  const data: [null[], number] = [sliders, logosInLineCount];

  return data;
};

export default useRowsLogosCount;
