import React from 'react';
import { Box } from '@konsus/superside-kit';
import styles from './AnimatedLogoSalad.module.css';
import { LogoSaladItem } from './LogoSaladItem';
import { type AnimatedLogoSaladProps, type InfiniteLoopSliderProps } from './types';
import useRowsLogosCount, { MINIMUM_LOGOS_IN_ONE_LINE } from './utils/useRowsLogosCount';

const InfiniteLoopSlider: React.FC<InfiniteLoopSliderProps> = ({
  children,
  speed,
  reverse = false
}) => {
  return (
    <div
      className={`${styles.appStyle} animate-loop flex h-[78px] w-fit items-center justify-center`}
      style={{
        '--duration-f777': `${(1 / speed) * 20000}ms`,
        '--direction-11d9': reverse ? 'reverse' : 'normal'
      }}
    >
      {children}
      {children}
      {children}
      {children}
    </div>
  );
};

export const AnimatedLogoSalad: React.FC<AnimatedLogoSaladProps> = ({ logos, rows, speed }) => {
  const [sliders, logosInLine] = useRowsLogosCount(logos.length, rows);

  return (
    <Box
      className={`${styles.animatedLogoSaladContainer} 768:gap-6  min-h-[78px] gap-0 overflow-hidden`}
    >
      {sliders &&
        sliders.length > 0 &&
        logosInLine >= MINIMUM_LOGOS_IN_ONE_LINE &&
        sliders?.map((_row: null, idr: number) => {
          const finalNumber = idr + 1 === sliders.length ? logos.length : (idr + 1) * logosInLine;
          const logosArray = logos?.slice(idr * logosInLine, finalNumber);

          return (
            <InfiniteLoopSlider key={idr} speed={speed} reverse={idr % 2 !== 0}>
              {logosArray.map((logo, idx) => (
                <LogoSaladItem animated key={idx} {...logo} />
              ))}
            </InfiniteLoopSlider>
          );
        })}
    </Box>
  );
};

export default AnimatedLogoSalad;
