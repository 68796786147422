import React from 'react';
import NextImage from '@konsus/image';
import { cn } from '@superside/ui';
import { type ImageAssetProps } from './types';

const ImageAsset: React.FC<ImageAssetProps> = ({ asset, className, style }) => {
  if (!asset) {
    return null;
  }

  return (
    <div
      className={cn(
        'z-1 absolute h-full w-full transition-transform duration-[800ms] ease-[cubic-bezier(0.22,0.68,0,1)] [&>span]:!h-full [&>span]:!w-full',
        className
      )}
      style={style}
    >
      <NextImage image={asset} alt='tedst' layout='responsive' objectFit='contain' />
    </div>
  );
};

export default ImageAsset;
