import React from 'react';
import { Box } from '@superside/ui';
import { Rating, type RatingProps } from './Rating';
import { trustpilotSvg, g2Svg } from './icons';

export interface StarRatingProps {
  isTransparent?: boolean;
  trustPilot: Pick<RatingProps, 'rating' | 'url'>;
  g2: Pick<RatingProps, 'rating' | 'url'>;
}

export const StarRating: React.FC<StarRatingProps> = ({
  isTransparent = false,
  trustPilot,
  g2
}) => {
  return (
    <Box
      {...(isTransparent ? {} : { glassy: 'fill' })}
      className='1280:gap-10 1280:p-6 flex flex-row items-center justify-start gap-8 rounded-lg p-4'
    >
      <Rating platform='Trustpilot' icon={trustpilotSvg} {...trustPilot} />
      <Rating platform='G2' icon={g2Svg} {...g2} />
    </Box>
  );
};

export default StarRating;
