import { useEffect, useState } from 'react';

const HEIGHT_DIVIDER = 300;

const useScrollRotate = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollProgress = scrollY / HEIGHT_DIVIDER;

      setProgress(scrollProgress);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return progress;
};

export default useScrollRotate;
