// @ts-ignore
import styles from './styles.module.css';

export const minimalCostNoteStyle = styles.minimalCostNoteStyle;
export const submitButtonWrapper = styles.submitButtonWrapper;
export const formStyle = styles.formStyle;
export const checkboxStyle = styles.checkboxStyle;
export const styledForm = styles.styledForm;
export const submitButton = styles.submitButton;
export const fullName = styles.fullName;
export const fullNameBox = styles.fullNameBox;
export const styledText = styles.styledText;
export const buttonWrap = styles.buttonWrap;
export const styledFormField = styles.styledFormField;
export const hubspotEmbedFormStyle = styles.hubspotEmbedFormStyle;
