import React from 'react';
import Script from 'next/script';

enum TrustpilotTheme {
  DARK = 'dark',
  LIGHT = 'light'
}

export interface TrustpilotReviewProps {
  templateId?: string;
  height?: number;
  stars: string;
  theme: TrustpilotTheme;
}

export const TrustpilotReview: React.FC<TrustpilotReviewProps> = ({
  templateId,
  height = 240,
  stars = '1,2,3,4,5',
  theme = TrustpilotTheme.DARK
}) => {
  if (!templateId) {
    return null;
  }

  return (
    <div className='min-w-full'>
      <Script
        strategy='lazyOnload'
        src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        async
      />

      <div
        className='trustpilot-widget'
        data-locale='en-US'
        data-template-id={templateId}
        data-businessunit-id='584b1f860000ff000598e1b8'
        data-style-height={`${height}px`}
        data-style-width='100%'
        data-theme={theme}
        data-stars={stars}
        data-review-languages='en'
        data-font-family='Raleway'
      />
    </div>
  );
};
